import React from 'react';
import { t, translate } from 'react-switch-lang';
import { PAYMENT_STATUS } from '../utils/Constants';
import './SkipToContent.scss';

function generateHandler(selector) {
  return () => {
    const e = document.querySelector(selector);
    if (!e) return;
    e.setAttribute('tabIndex', -1);
    e.focus();
  };
}

function SkipToContent({ status }) {
  return (
    <div id="SkipTo">
      {(status === PAYMENT_STATUS.AVAILABLE || status === PAYMENT_STATUS.INITIATED) &&
        <SkipToBtn skipTo="#InfoBlock" textKey="LinkDescription_SkipToPaymentInstructions" />}
      {status === PAYMENT_STATUS.AVAILABLE ? (
        <SkipToBtn skipTo="#PayOptions" textKey="LinkDescription_SkipToPaymentOptions" />
      ) : (
        <SkipToBtn skipTo="#content" textKey="LinkDescription_SkipToMain" />
      )}
      <SkipToBtn skipTo="footer" textKey="LinkDescription_SkipToFooter" />
    </div>
  );
}

/**
 * @param {object} props
 * @param {string} [props.text] either text or textKey should be specified
 * @param {string} [props.textKey] either text or textKey should be specified
 * @param {string} [props.skipTo] query selector for the element to skip to
 */
export function SkipToBtn({ text, textKey, skipTo, ...args }) {
  return <button type="button" className="btn" onClick={generateHandler(skipTo)} {...args}>{text || t(textKey)}</button>;
}

export default translate(SkipToContent);
