import { t } from 'react-switch-lang';
import { events, logAmpEvent } from '../utils/Amplitude';

/** opens in a new tab by default; specify target and rel props to change this behaviour */
export default function Link({
  href, children, text = '', ampEvent, ampEventProps, ...props
}) {
  if (!props.target || props.target === '_blank') {
    const suffix = ` - ${t('LinkDescription_TargetBlank')}`;

    // append to existing link descriptions
    if (props.title) props.title += suffix; // eslint-disable-line no-param-reassign
    if (props['aria-label']) props['aria-label'] += suffix; // eslint-disable-line no-param-reassign

    // add default link description if there aren't any
    if (!props.title && !props['aria-label'] && (text || typeof children === 'string')) {
      props['aria-label'] = `${(text || children)}${suffix}`; // eslint-disable-line no-param-reassign
    }
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      {...props}
      onClick={
        (props.onClick || ampEvent) &&
        ((e) => {
          if (ampEvent && events[ampEvent]) logAmpEvent(ampEvent, ampEventProps);
          props.onClick?.(e);
        })
      }
    >
      {text || children}
    </a>
  );
}
