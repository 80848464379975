import React from 'react';
import { getLanguage, setLanguage, t, translate } from 'react-switch-lang';

import { logAmpEvent, events, getPartnerName } from '../utils/Amplitude';
import './Footer.scss';
import directPayLogo from '../images/DirectPay.png';
import Link from './Link';

function Footer() {
  const newLang = getLanguage() === 'fr' ? 'en' : 'fr';
  return (
    <footer>
      <p>{t('HowItWorks_Info3', { partner: getPartnerName() })}</p>
      <div>
        <img src={directPayLogo} alt={t('ImageDescription_DirectPayLogo')} />
        <div id="links">
          <Link
            href={t('Link_DirectPay_Privacy')}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: 'Privacy Policy' }}
            text={t('Footer_Lbl_Privacy')}
          />
          <Link
            href={t('Link_AccessibilityStatement')}
            ampEvent={events.USER_CLICKED_FOOTER_LINK}
            ampEventProps={{ Link: 'Accessibility' }}
            text={t('Footer_Lbl_Accessibility')}
          />
          <button
            type="button"
            aria-label={t('ButtonDescription_SwitchLanguage', null, newLang)}
            lang={newLang}
            onMouseDown={(e) => e.preventDefault()} // prevent button from staying focused
            onClick={() => {
              logAmpEvent(events.USER_CHANGED_LANGUAGE, { Language: newLang === 'en' ? 'English' : 'French' });
              setLanguage(newLang);
              const searchParams = new URLSearchParams(window.location.search);
              searchParams.set('lang', newLang);
              const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}${window.location.hash}`;
              history.replaceState(null, '', newRelativePathQuery); // eslint-disable-line no-restricted-globals
            }}
          >
            {newLang === 'en' ? 'English' : 'Français'}
          </button>
        </div>
        <div id="disclaimer">
          <p>{t('Footer_Disclaimer')}</p>
          <p>{t('Footer_Disclaimer2')}</p>
        </div>
      </div>
    </footer>
  );
}

export default translate(Footer);
