import React from 'react';
import ReactDOM from 'react-dom';
import { setTranslations, setLanguage, t } from 'react-switch-lang';
import { setUser as setSentryUser } from '@sentry/react';

import App from './App';
import jwtVerify from './utils/Jwt';
import { isProduction } from './utils/HostingEnv';
import initSentry from './utils/Sentry';
import { events, initAmplitude, logAmpEvent, setAmpUser, setPartnerName } from './utils/Amplitude';
import PaymentDemo from './components/PaymentDemo';
import ErrorPage from './components/ErrorPage';
import LoadingPage from './components/LoadingPage';
import English from './languages/English.json';
import French from './languages/French.json';
import CustomErrorPage from './components/CustomErrorPage';

if (isProduction) initSentry();
setTranslations({
  en: English,
  fr: French,
});

const searchParam = new URLSearchParams(window.location.search);
const seq = searchParam.get('pps');
const jwt = searchParam.get('jwtHost');
const testMode = searchParam.get('TestMode') === 'y';
const showFakeBankPage = !!searchParam.get('payment_demo');
const amount = parseFloat(searchParam.get('amount')) || 0;
const loadingInteracPage = searchParam.get('loading') === 'true';
const errorCode = searchParam.get('error_code');
const lang = searchParam.get('lang') === 'fr' ? 'fr' : 'en';
setLanguage(lang);
document.documentElement.setAttribute('lang', lang); // set <html> lang attr
setPartnerName(searchParam); // store pne & pnf searchParams when the app is loaded
initAmplitude(testMode);
if (testMode && showFakeBankPage) {
  const id = searchParam.get('id');
  if (id) {
    setAmpUser(id);
    if (isProduction) setSentryUser({ id });
  }
}

if (loadingInteracPage) {
  ReactDOM.render(
    (
      <LoadingPage>
        <h1>{t('PaymentInitiated_LoadingInteracPage1', null, lang)}</h1>
        <p>{t('PaymentInitiated_LoadingInteracPage2', null, lang)}</p>
      </LoadingPage>
    ), document.getElementById('root')
  );
} else if (errorCode) {
  ReactDOM.render(<CustomErrorPage
    errorCode={errorCode}
    lang={searchParam.get('lang')}
    returnUrl={searchParam.get('return')}
  />, document.getElementById('root'));
} else {
  jwtVerify(jwt, seq).then((expiration) => {
    // allow 12 hour window in case user's clock is manually
    // set ahead instead of being set to the proper time zone;
    // backend will still verify the expiration properly
    const expPlus12Hours = expiration ? expiration + 43200000 : false;

    if (isProduction && expPlus12Hours && (!searchParam.get('pne') || !searchParam.get('pnf'))) {
      logAmpEvent(events.NO_PARTNER_NAME_PROVIDED);
    }
    if (!expPlus12Hours) {
      ReactDOM.render(<ErrorPage ampProp={{ Error: 'Invalid query parameters.' }} />, document.getElementById('root'));
    } else if (expPlus12Hours < Date.now()) {
      ReactDOM.render(<ErrorPage
        titleKey="PaymentExpired_Title"
        ampEvent={events.USER_VIEWED_JWT_EXPIRED_PAGE}
        ampProp={{ PartnerPaySeq: seq }}
      />, document.getElementById('root'));
    } else if (showFakeBankPage) {
      ReactDOM.render(<PaymentDemo seq={seq} jwt={jwt} exp={expPlus12Hours} amount={amount} />, document.getElementById('root'));
    } else {
      ReactDOM.render(<App seq={seq} jwt={jwt} exp={expPlus12Hours} testMode={testMode} />, document.getElementById('root'));
    }
  });
}
