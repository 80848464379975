import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { events, logAmpEvent } from '../utils/Amplitude';

import './Collapsible.scss';

function Collapsible({
  isCollapsed: isCollapsedProp,
  collapsedUI,
  btnDescriptionKey,
  contentId,
  children,
  ...props
}) {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedProp);
  const handleClick = () => {
    logAmpEvent(
      isCollapsed ?
        events.USER_OPENED_PAYMENT_INSTRUCTIONS :
        events.USER_CLOSED_PAYMENT_INSTRUCTIONS
    );
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div
      {...props}
      className={`collapsible ${isCollapsed ? 'collapsed' : 'expanded'} ${props.className || ''}`}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyPress={handleClick}
        aria-expanded={!isCollapsed}
        aria-controls={contentId}
      >
        {collapsedUI}
        <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
      </div>
      <CollapsibleBody id={contentId} isCollapsed={isCollapsed}>
        {children}
      </CollapsibleBody>
    </div>
  );
}

/** This must be used with a separate control that expands/collapses the content */
export function CollapsibleBody({ isCollapsed = false, children, className = '', ...props }) {
  return (
    <div className={`collapsibleBody ${className}`} aria-hidden={isCollapsed} {...props}>
      <div>{children}</div>
    </div>
  );
}

export default Collapsible;
