import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { t, translate } from 'react-switch-lang';
import { toDataURL } from 'qrcode';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingUsd } from '@fortawesome/pro-solid-svg-icons';

import CancelPaymentBtn from './CancelPaymentBtn';
import IconTextContainer from '../IconTextContainer';
import { DEVICE_OPTION, PAYMENT_STATUS, AMP_PROP_EXPERIENCE_TYPE } from '../../utils/Constants';
import { ERROR, queryPayment } from '../../utils/ApiClient';
import { events, logAmpEvent } from '../../utils/Amplitude';
import { setPageTitle } from '../../utils/PageTitle';
import PartnerPayContext from '../../PartnerPayContext';
import './PaymentInitiated.scss';
import { useWindowFocusStateListener } from '../../utils/Helpers';
import Link from '../Link';

const pollingInterval = 8000;

function PaymentInitiated({
  device = DEVICE_OPTION.THIS_DEVICE,
  setDevice,
  setStatus,
  returnUrl,
  paymentUrl,
}) {
  const { jwt, seq, exp, testMode } = useContext(PartnerPayContext);
  const [QRCode, setQRCode] = useState();
  const [isWindowFocusedRef, setOnFocusCallback] = useWindowFocusStateListener();
  const fetchFailureCountRef = useRef(0);

  const query = useCallback(async () => {
    // for mobile, only make query calls when focused on the page
    if (isMobile && !isWindowFocusedRef.current) return;
    const resp = await queryPayment(jwt, seq, exp, true);
    if (resp.Status === ERROR && resp.ErrorName === 'TypeError') {
      fetchFailureCountRef.current += 1;
      if (fetchFailureCountRef.current < 3) return; // retry until third fetch failure
    } else {
      fetchFailureCountRef.current = 0;
    }

    if (testMode && resp.Status === PAYMENT_STATUS.AVAILABLE) return;
    if (resp.Status === PAYMENT_STATUS.INITIATED) return;
    if (resp.Status === PAYMENT_STATUS.DEPOSITED) logAmpEvent(events.PAYMENT_RECEIVED);
    setStatus(resp.Status);
  }, [exp, jwt, seq, setStatus, testMode, isWindowFocusedRef]);

  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_WAITING_FOR_PAYMENT_PAGE, { 'Experience Type': AMP_PROP_EXPERIENCE_TYPE[device] });
    setPageTitle(t('PageTitle_PaymentInitiated'));
  }, [device]);

  useEffect(() => {
    const pollingId = setInterval(query, pollingInterval);

    return () => clearInterval(pollingId);
  }, [query]);

  useEffect(() => {
    // query immediately on focus for mobile
    if (!isMobile) return;
    setOnFocusCallback(() => query);
  }, [query, setOnFocusCallback]);

  useEffect(() => {
    if (paymentUrl) toDataURL(paymentUrl).then(setQRCode);
  }, [paymentUrl]);

  if (device === DEVICE_OPTION.PHONE) {
    return (
      <>
        <ChangeDeviceBtn curDevice={device} setDevice={setDevice} paymentUrl={paymentUrl} />
        <div id="ScanQR">
          <h2>{t('PaymentInitiated_Phone_Help1')}</h2>
          <p>{t('PaymentInitiated_Phone_Help2')}</p>
          {QRCode ? <img src={QRCode} alt="QR code" /> : <FontAwesomeIcon icon={faSpinnerThird} spin size="3x" />}
        </div>
        <CancelPaymentBtn returnUrl={returnUrl} status={PAYMENT_STATUS.INITIATED} />
        <hr />
        <IconTextContainer icon={faHandHoldingUsd} title="PaymentInitiated_Title" withSpinner>
          <p>{t(`PaymentInitiated_${device}_Info`)}</p>
        </IconTextContainer>
      </>
    );
  }
  return (
    <>
      {!isMobile && (
        <ChangeDeviceBtn curDevice={device} setDevice={setDevice} paymentUrl={paymentUrl} />
      )}
      <IconTextContainer icon={faHandHoldingUsd} title="PaymentInitiated_Title" withSpinner>
        <p>{t(`PaymentInitiated_${device}_Info`)}</p>
        <p id="Help">
          <span>{t('PaymentInitiated_ThisDevice_Help1')}</span>
          {' '}
          <Link
            href={paymentUrl}
            ampEvent={events.USER_CLICKED_OPEN_INTERAC_LINK_AGAIN}
            aria-label={t('LinkDescription_OpenInteracLinkAgain')}
            text={t('PaymentInitiated_ThisDevice_Help2')}
          />
        </p>
      </IconTextContainer>
      <CancelPaymentBtn returnUrl={returnUrl} status={PAYMENT_STATUS.INITIATED} />
    </>
  );
}

function ChangeDeviceBtn({ curDevice, setDevice, paymentUrl }) {
  const changeTo =
    curDevice === DEVICE_OPTION.THIS_DEVICE ? DEVICE_OPTION.PHONE : DEVICE_OPTION.THIS_DEVICE;
  return (
    <button
      type="button"
      id="ChangeDevice"
      className="linkBtn"
      onClick={() => {
        logAmpEvent(events.USER_CLICKED_CHANGE_PAYMENT_EXPERIENCE_TYPE, { 'Change To': AMP_PROP_EXPERIENCE_TYPE[changeTo] });
        setDevice(changeTo);
        if (changeTo === DEVICE_OPTION.THIS_DEVICE && paymentUrl) window.open(paymentUrl);
      }}
    >
      {t(`PaymentInitiated_${curDevice}_ChangeDevice`)}
    </button>
  );
}

export default translate(PaymentInitiated);
