import { translate } from 'react-switch-lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import './IconTextContainer.scss';

/**
 * @param {object} props
 * @param {boolean} props.withSpinner
 * @param {import('@fortawesome/fontawesome-svg-core').IconProp} props.icon
 * @param {string} props.title key in lang file
 */
function IconTextContainer({ t, withSpinner, icon, title, children, ...props }) {
  return (
    <div {...props} className={`iconTextCont ${props.className || ''}`}>
      {withSpinner ? (
        <div className="fa-stack fa-2x">
          <FontAwesomeIcon icon={faSpinnerThird} spin className="fa-stack-2x" />
          <FontAwesomeIcon icon={icon} className="fa-stack-1x" />
        </div>
      ) : (
        <FontAwesomeIcon icon={icon} size="6x" />
      )}
      <h1>{t(title)}</h1>
      {children}
    </div>
  );
}

export default translate(IconTextContainer);
