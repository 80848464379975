import { useContext, useEffect, useState } from 'react';
import { t, translate } from 'react-switch-lang';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDisplay, faMobile } from '@fortawesome/pro-regular-svg-icons';

import CancelPaymentBtn from './CancelPaymentBtn';
import Button from '../Button';
import { PAYMENT_STATUS, DEVICE_OPTION, AMP_PROP_EXPERIENCE_TYPE } from '../../utils/Constants';
import { initiatePayment } from '../../utils/ApiClient';
import { events, getAmpUser, logAmpEvent } from '../../utils/Amplitude';
import { setPageTitle } from '../../utils/PageTitle';
import PartnerPayContext from '../../PartnerPayContext';
import './PaymentAvailable.scss';
import { useWindowFocusStateListener } from '../../utils/Helpers';

function PaymentAvailable({ setDevice, setStatus, returnUrl, setPaymentUrl, amount }) {
  const { jwt, seq, exp, testMode } = useContext(PartnerPayContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_ARRIVAL_PAGE);
    setPageTitle(t('PageTitle_PaymentAvailable'));
  }, []);
  useWindowFocusStateListener();

  const handler = async (option, newWindow) => {
    let newStatus;
    let paymentUrl;

    setLoading(true);

    try {
      if (testMode) {
        if (exp < Date.now()) {
          setStatus(PAYMENT_STATUS.EXPIRED);
          return;
        }
        newStatus = PAYMENT_STATUS.INITIATED;
        paymentUrl = `${window.location.href}&payment_demo=y&amount=${amount}&id=${encodeURIComponent(getAmpUser())}`;
      } else {
        const resp = await initiatePayment(jwt, seq, exp);
        setLoading(false);
        newStatus = resp.Status;
        paymentUrl = resp.PaymentURL;
        if (!newStatus) return;
        if (!paymentUrl) {
          setStatus(newStatus);
          return;
        }
      }
      setDevice(option);
      setPaymentUrl(paymentUrl);
      setStatus(newStatus);
    } finally {
      if (newWindow) {
        if (newStatus === PAYMENT_STATUS.INITIATED && paymentUrl) {
          newWindow.location.replace(paymentUrl); // eslint-disable-line no-param-reassign
        } else {
          newWindow.close();
        }
      }
    }
  };

  return (
    <>
      <h1 id="LandingTitle">{t('Landing_Title')}</h1>
      <div id="PayOptions">
        <PayOption
          icon={isMobile ? faMobile : faDisplay}
          option={DEVICE_OPTION.THIS_DEVICE}
          onClick={handler}
          loading={loading}
        />
        {!isMobile && (
          <PayOption
            icon={faMobile}
            option={DEVICE_OPTION.PHONE}
            onClick={handler}
            loading={loading}
          />
        )}
      </div>
      <CancelPaymentBtn returnUrl={returnUrl} status={PAYMENT_STATUS.AVAILABLE} />
    </>
  );
}

function PayOption({ icon, option, onClick, loading }) {
  return (
    <div className="payOption">
      <FontAwesomeIcon icon={icon} fixedWidth />
      <h2>{t(`Landing_PayOption_${option}_Title`)}</h2>
      <p>{t(`Landing_PayOption_${option}_Info`)}</p>
      <Button
        onClick={() => {
          const windowHref = new URL(window.location.href);
          windowHref.searchParams.append('loading', 'true');
          onClick(option, option === DEVICE_OPTION.THIS_DEVICE && window.open(windowHref.href));
        }}
        text={t(`Landing_PayOption_${option}_Btn`)}
        loading={loading}
        ampEvent={events.USER_CLICKED_PAY_NOW}
        ampEventProps={{ 'Experience Type': AMP_PROP_EXPERIENCE_TYPE[option] }}
        ampIsMobile={isMobile}
      />
    </div>
  );
}

export default translate(PaymentAvailable);
