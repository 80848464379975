import { jwtVerify, importSPKI } from 'jose';
import { isProduction } from './HostingEnv';
import { events, logAmpEvent } from './Amplitude';

let publicKey;
const environment = isProduction ? 'Production' : 'Development';

async function init() {
  publicKey = await importSPKI(process.env.REACT_APP_JWT_PUBLIC_KEY, 'RS256');
}

/**
 * Verifies the given JWT and returns its expiration time.
 * @param {string} jwt
 * @param {string} seq PartnerPaySeq
 * @returns expiration time in milliseconds if valid, otherwise false
 */
export default async function verify(jwt, seq) {
  if (!jwt) {
    // on testserver, allow no jwt (debug mode)
    if (process.env.REACT_APP_ENV === 'test') {
      window.debug = true;
      // you can leave the debug mode page for an hour until it shows the EXPIRED screen
      return Date.now() + 3600000;
    }
    return false;
  }

  try {
    if (!publicKey) await init();
    const { payload } = await jwtVerify(jwt, publicKey, {
      algorithms: ['RS256'],
      typ: 'JWT',
      clockTolerance: 600,
    });

    const e = new Error();
    if (payload.unique_name !== seq) {
      e.message = `SeqMismatch - JWT: ${payload.unique_name}, Seq: ${seq}`;
      throw e;
    }
    if (payload.System !== environment) {
      e.message = `EnvMismatch - JWT: ${payload.System}, Env: ${environment}`;
      throw e;
    }
    return payload.exp * 1000;
  } catch (e) {
    if (e.code === 'ERR_JWT_EXPIRED') return JSON.parse(atob(jwt.split('.')[1])).exp * 1000;
    logAmpEvent(events.JWT_ERROR, { Description: e.message, PartnerPaySeq: seq });
    return false;
  }
}
