export const DEVICE_OPTION = Object.freeze({
  THIS_DEVICE: 'ThisDevice',
  PHONE: 'Phone',
});
export const AMP_PROP_EXPERIENCE_TYPE = Object.freeze({
  ThisDevice: 'This Device',
  Phone: 'Phone Hand Off',
});

export const PAYMENT_STATUS = Object.freeze({
  AVAILABLE: 'AVAILABLE',
  INITIATED: 'INITIATED',
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  CANCELLED: 'CANCELLED',
  DEPOSITED: 'DEPOSITED',
  REJECTED: 'REJECTED',
  FAILED: 'FAILED',
  EXPIRED: 'EXPIRED',
  UNKNOWN: 'UNKNOWN',
});

export const API_CALL = Object.freeze({
  QUERY: 'QueryPayment',
  INTIATE: 'InitiatePayment',
  CANCEL: 'CancelPayment',
});
