import { v4 as uuid } from 'uuid';
import { captureException, captureMessage } from '@sentry/react';
import { isDevelopment, isProduction } from './HostingEnv';
import { PAYMENT_STATUS, API_CALL } from './Constants';
import { events, logAmpEvent } from './Amplitude';

const url = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_API_KEY;
export const NOT_FOUND = 404;
export const ERROR = 500;

const log = isDevelopment ? (path, data) => {
  // eslint-disable-next-line no-console
  console.log(`API Call to: %c${path}`, 'background-color: greenyellow;color:black;', data);
} : () => {};

const Amount = process.env.REACT_APP_ENV === 'test' ? (Math.random() * 200).toFixed(2) : null;
const dummyResp = process.env.REACT_APP_ENV === 'test' ? async (Call, path) => {
  const windowHref = new URL(window.location.href);
  windowHref.searchParams.append('payment_demo', 'y');
  windowHref.searchParams.append('amount', Amount);
  let Status = window.paymentStatus || PAYMENT_STATUS.AVAILABLE;
  if (Call === API_CALL.CANCEL) Status = PAYMENT_STATUS.CANCELLED;
  if (Call === API_CALL.INTIATE) {
    window.paymentStatus = PAYMENT_STATUS.INITIATED;
    Status = PAYMENT_STATUS.INITIATED;
  }
  const resp = {
    Amount,
    Status,
    PaymentURL: windowHref.href,
    ReturnURL: window.location.href,
  };
  await (new Promise((r) => { setTimeout(r, resp.Status === PAYMENT_STATUS.AVAILABLE ? 10 : 500); })); // eslint-disable-line max-len
  log(`${path} (DEBUG)`, resp);
  if (Call === API_CALL.INTIATE) window.paymentStatus = PAYMENT_STATUS.DEPOSITED;
  return resp;
} : async () => {};

const makeCall = async (
  method,
  path,
  jwt,
  exp = 0,
  reqBody = undefined,
  disableSuccessAmpLog = false
) => {
  if (exp !== 0 && exp < Date.now()) return { Status: PAYMENT_STATUS.EXPIRED };

  let Call;
  switch (path.split('/')[1]) {
    case 'query':
      Call = API_CALL.QUERY;
      break;
    case 'initiate':
      Call = API_CALL.INTIATE;
      break;
    case 'cancel':
      Call = API_CALL.CANCEL;
      break;
    default:
      Call = 'Not set';
  }

  if (process.env.REACT_APP_ENV === 'test' && window.debug) return dummyResp(Call, path);

  const response = await fetch(url + path, {
    method,
    headers: {
      'x-api-key': token,
      'x-api-uid': uuid(),
      HostPaymentToken: jwt,
    },
    body: reqBody ? JSON.stringify(reqBody) : reqBody,
  })
    .then(async (res) => {
      if (res.headers.get('Content-Type') !== 'application/json') throw new Error(`${res.status}: ${await res.text()}`);
      const respBody = await res.json();
      log(path, { url: url + path, reqBody, respBody, respStatus: res.status });

      switch (res.status) {
        case 200:
          break;
        case 404:
          respBody.Status = NOT_FOUND;
          break;
        case 401:
          if (respBody.Message === 'Token has expired.') {
            respBody.Status = PAYMENT_STATUS.EXPIRED;
            break;
          }
          // falls through
        case 400:
        case 500:
        case 503:
        default:
          respBody.Status = ERROR;
          break;
      }

      if (res.status === 200) {
        // verify that the payment url is from Interac
        if (respBody.Status === PAYMENT_STATUS.INITIATED && new URL(respBody.PaymentURL).hostname.slice(-10) !== 'interac.ca') {
          const errMsg = `Invalid payment URL: ${respBody.PaymentURL}`;
          if (isProduction) captureMessage(errMsg, 'fatal');
          logAmpEvent(events.API_ERROR, {
            Call,
            Description: errMsg,
          });
          return { Status: ERROR };
        }
        if (!disableSuccessAmpLog) logAmpEvent(events.API_SUCCESS, { Call });
      } else {
        logAmpEvent(events.API_ERROR, {
          Call,
          ResultCode: res.status,
          Description: respBody.Message,
        });
        if (isProduction) captureMessage(`${res.status}: ${JSON.stringify(respBody)}`);
      }
      return respBody;
    })
    .catch((err) => {
      logAmpEvent(events.API_ERROR, {
        Call,
        Description: err.toString(),
      });
      if (isProduction) captureException(err);
      return { Status: ERROR, ErrorName: err.name, ErrorMessage: err.message };
    });

  return response;
};

export const queryPayment = (jwt, seq, exp, isPolling) => makeCall('GET', `/query/${seq}`, jwt, exp, undefined, isPolling);
export const initiatePayment = (jwt, seq, exp) => makeCall('POST', '/initiate', jwt, exp, {
  PartnerPaySeq: seq,
});
export const cancelPayment = (jwt, seq, exp) => makeCall('POST', '/cancel', jwt, exp, {
  PartnerPaySeq: seq,
  CancelReason: 'User Cancel',
});
