import React, { useState, useEffect } from 'react';
import { t, translate } from 'react-switch-lang';

import InfoBlock from './InfoBlock';
import PaymentAvailable from './PaymentAvailable';
import PaymentInitiated from './PaymentInitiated';
import PaymentDeposited from './PaymentDeposited';
import { ErrorContent } from '../ErrorPage';
import { PAYMENT_STATUS } from '../../utils/Constants';
import { NOT_FOUND, ERROR } from '../../utils/ApiClient';
import { events, getPartnerName, getPlatform } from '../../utils/Amplitude';
import './Main.scss';
import ProgressBar from './ProgressBar';
import PaymentProcessing from './PaymentProcessing';
import { ItalicizeInterac } from '../ReplaceTextWithElement';
import Link from '../Link';

function Main({ status, setStatus, returnUrl, paymentUrl: initialPaymentUrl, amount }) {
  const [device, setDevice] = useState();
  const [paymentUrl, setPaymentUrl] = useState(initialPaymentUrl);
  useEffect(() => {
    if (status === PAYMENT_STATUS.VERIFIED || status === PAYMENT_STATUS.PENDING) return;
    window.scrollTo({ top: 0 });
  }, [status]);
  let mainUI;
  let shouldDisplayInfoBlock = false;
  let collapseInfoBlock = false;
  let shouldDisplayProgressBar = false;

  switch (status) {
    case PAYMENT_STATUS.AVAILABLE:
      shouldDisplayInfoBlock = true;
      mainUI = (
        <PaymentAvailable
          setDevice={setDevice}
          setStatus={setStatus}
          returnUrl={returnUrl}
          setPaymentUrl={setPaymentUrl}
          amount={amount}
        />
      );
      break;
    case PAYMENT_STATUS.INITIATED:
      shouldDisplayInfoBlock = true;
      shouldDisplayProgressBar = true;
      collapseInfoBlock = true;
      mainUI = (
        <PaymentInitiated
          device={device}
          setDevice={setDevice}
          setStatus={setStatus}
          returnUrl={returnUrl}
          paymentUrl={paymentUrl}
        />
      );
      break;
    case PAYMENT_STATUS.VERIFIED:
    case PAYMENT_STATUS.PENDING:
      shouldDisplayProgressBar = true;
      mainUI = <PaymentProcessing setStatus={setStatus} />;
      break;
    case PAYMENT_STATUS.DEPOSITED:
      shouldDisplayProgressBar = true;
      mainUI = <PaymentDeposited returnUrl={returnUrl} device={device} />;
      break;
    case PAYMENT_STATUS.EXPIRED:
      mainUI = (
        <ErrorContent
          returnUrl={returnUrl}
          titleKey="PaymentExpired_Title"
          ampEvent={events.USER_VIEWED_PAYMENT_STATUS_PAGE}
          ampProp={{ Status: 'Expired' }}
          pageTitleKey="PageTitle_PaymentExpired"
        >
          <div id="PaymentExpired">
            <p>{t('ErrorPage_Body', { partner: getPartnerName() })}</p>
            <h2>{t('PaymentExpired_AcceptedRFM_Heading')}</h2>
            <p><ItalicizeInterac text={t('PaymentExpired_AcceptedRFM_Body')} /></p>
            <p><ItalicizeInterac text={t('HowFundsWillBeReturned')} /></p>
            <Link
              href={t('FundsHeldByInterac_HelpLink')}
              ampEvent={events.USER_CLICKED_FUNDS_HELD_HELP_LINK}
              text={t('PaymentExpired_AcceptedRFM_LinkLabel')}
            />
          </div>
        </ErrorContent>
      );
      break;
    case PAYMENT_STATUS.CANCELLED:
      mainUI = (
        <ErrorContent
          returnUrl={returnUrl}
          titleKey="PaymentCancelled_Title"
          ampEvent={events.USER_VIEWED_PAYMENT_STATUS_PAGE}
          ampProp={{ Status: 'Cancelled' }}
          pageTitleKey="PageTitle_PaymentCancelled"
        >
          {getPlatform() === 'Shopify' && (
            <div id="PaymentCancelled">
              <p>{t('PaymentCancelled_Info')}</p>
              <ol>
                <li>{t('PaymentCancelled_Point1', { partner: getPartnerName() })}</li>
                <li>{t('PaymentCancelled_Point2')}</li>
                <li>{t('PaymentCancelled_Point3')}</li>
                <li>{t('PaymentCancelled_Point4')}</li>
              </ol>
            </div>
          )}
        </ErrorContent>
      );
      break;
    case PAYMENT_STATUS.REJECTED:
      mainUI = (
        <ErrorContent
          returnUrl={returnUrl}
          titleKey="PaymentRejected_Title"
          ampEvent={events.USER_VIEWED_PAYMENT_STATUS_PAGE}
          ampProp={{ Status: 'Rejected' }}
          pageTitleKey="PageTitle_PaymentRejected"
        >
          <div id="PaymentRejected">
            <p><ItalicizeInterac text={t('HowFundsWillBeReturned')} /></p>
            <p>{t('PaymentRejected_Info1')}</p>
            <ul>
              <li>{t('PaymentRejected_Point1', { partner: getPartnerName() })}</li>
              <li>{t('PaymentRejected_Point2', { partner: getPartnerName() })}</li>
              <li>{t('PaymentRejected_Point3', { partner: getPartnerName() })}</li>
            </ul>
            <p>{t('PaymentRejected_Info2', { partner: getPartnerName() })}</p>
          </div>
        </ErrorContent>
      );
      break;
    case NOT_FOUND:
      mainUI = (
        <ErrorContent
          returnUrl={returnUrl}
          titleKey="Error404_Title"
          ampEvent={events.USER_VIEWED_404_ERROR_PAGE}
          pageTitleKey="PageTitle_404"
        />
      );
      break;
    case ERROR:
      mainUI = <ErrorContent returnUrl={returnUrl} ampProp={{ Error: 'Query payment failed.' }} />;
      break;
    default:
      // TODO: missing UI states for some payment statuses
      mainUI = <ErrorContent returnUrl={returnUrl} ampProp={{ Error: `Payment Status: ${status}` }} />;
  }

  return (
    <>
      {shouldDisplayInfoBlock && <InfoBlock collapsed={collapseInfoBlock} />}
      {shouldDisplayProgressBar && <ProgressBar status={status} />}
      <div id="content">
        {mainUI}
      </div>
    </>
  );
}

export default translate(Main);
