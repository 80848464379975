import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import './LoadingPage.scss';

function LoadingPage({ children }) {
  return (
    <div id="Loading">
      <FontAwesomeIcon icon={faSpinnerThird} spin size="3x" />
      {children}
    </div>
  );
}

export default LoadingPage;
