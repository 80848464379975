import { useEffect, useState } from 'react';
import { getLanguage, t } from 'react-switch-lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

import { ItalicizeInterac } from './ReplaceTextWithElement';
import Button from './Button';
import { formatCurrency } from '../utils/Helpers';
import { initiatePayment } from '../utils/ApiClient';
import { PAYMENT_STATUS } from '../utils/Constants';
import { events, logAmpEvent } from '../utils/Amplitude';
import PageTitle from '../utils/PageTitle';
import interacLogo from '../images/Interac.svg';
import bankLogo from '../images/DemoBankLogo.png';
import './PaymentDemo.scss';

function BankLogo() {
  return <img src={bankLogo} alt={t('ImageDescription_FakeBankLogo')} />;
}

export default function PaymentDemo({ jwt, seq, exp, amount }) {
  const [bankSelected, setBankSelected] = useState(false);

  return bankSelected ?
    <FakeBankPage amount={amount} jwt={jwt} seq={seq} exp={exp} /> :
    <FakeInteracPage amount={amount} setBankSelected={setBankSelected} />;
}

function Layout({ pageTitle, headerComp, bannerTextKey, children }) {
  return (
    <>
      <PageTitle title={pageTitle} />
      <div id="PaymentDemoPage">
        <header>{headerComp}</header>
        <div id="Banner">{t(bannerTextKey)}</div>
        <main className="maxWidth">{children}</main>
      </div>
    </>
  );
}

function FakeInteracPage({ amount, setBankSelected }) {
  useEffect(() => logAmpEvent(events.USER_VIEWED_FAKE_INTERAC_PAGE), []);
  return (
    <Layout
      pageTitle={t('PageTitle_FakeInterac')}
      bannerTextKey="FakeInteracPage_Banner"
      headerComp={(
        <div id="InteracHeader">
          <div className="maxWidth">
            <img src={interacLogo} alt={t('ImageDescription_InteracLogo')} width={50} />
          </div>
        </div>
      )}
    >
      <div id="InteracInfo">
        <h1>{t('FakeInteracPage_Title')}</h1>
        <div id="Amount">
          <span>
            {formatCurrency(amount, getLanguage())}
          </span>
          <span> CAD</span>
        </div>
      </div>
      <div id="ChooseBank">
        <h2>{t('FakeInteracPage_SelectBank')}</h2>
        <div id="Banks">
          <div>
            <button
              type="button"
              onClick={() => {
                logAmpEvent(events.USER_SELECTED_FAKE_BANK);
                setBankSelected(true);
              }}
            >
              <BankLogo />
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function FakeBankPage({ amount, jwt, seq, exp }) {
  const [moneySent, setMoneySent] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => logAmpEvent(events.USER_VIEWED_FAKE_BANK_PAGE), []);
  return (
    <Layout
      pageTitle={t('PageTitle_FakeBank')}
      bannerTextKey="FakeBankPage_Banner"
      headerComp={(
        <div id="BankHeader">
          <div className="maxWidth"><BankLogo /></div>
        </div>
      )}
    >
      {!moneySent ? (
        <div id="AcceptPayment">
          <h1><ItalicizeInterac text={t('FakeBankPage_Title')} /></h1>
          <div id="Amount">{formatCurrency(amount, getLanguage())}</div>
          <Button
            ampEvent={events.USER_CLICKED_FAKE_BANK_ACCEPT_RFM}
            onClick={async () => {
              setError(false);
              const res = await initiatePayment(jwt, seq, exp);
              // currently Payment Engine returns DEPOSITED
              // for InitiatePayment calls in TestMode
              if (
                res.Status === PAYMENT_STATUS.INITIATED ||
                res.Status === PAYMENT_STATUS.DEPOSITED
              ) {
                setMoneySent(true);
                return;
              }
              setError(true);
            }}
            text={t('FakeBankPage_Btn')}
          />
          {error && <p id="ErrorMsg">{t('ErrorPage_Title')}</p>}
        </div>
      ) : (
        <div id="AcceptPayment">
          <div id="MoneySent">
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
            <h1 id="MoneySent">{t('FakeBankPage_MoneySent')}</h1>
          </div>
        </div>
      )}
    </Layout>
  );
}
