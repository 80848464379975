import { useContext } from 'react';
import { t, translate } from 'react-switch-lang';
import PartnerPayContext from '../../PartnerPayContext';
import { cancelPayment } from '../../utils/ApiClient';
import { events, getPartnerName, logAmpEvent } from '../../utils/Amplitude';
import { PAYMENT_STATUS } from '../../utils/Constants';

function CancelPaymentBtn({ returnUrl, status }) {
  const { jwt, seq, exp } = useContext(PartnerPayContext);
  let cancelAllowed = false;
  let page;
  switch (status) {
    case PAYMENT_STATUS.AVAILABLE:
      page = 'Arrival';
      cancelAllowed = true;
      break;
    case PAYMENT_STATUS.INITIATED:
      page = 'Waiting for Payment';
      cancelAllowed = true;
      break;
    case PAYMENT_STATUS.PENDING:
    case PAYMENT_STATUS.VERIFIED:
      page = 'Processing Your Payment';
      break;
    default:
      throw new Error();
  }
  return (
    <button
      type="button"
      id="CancelPayment"
      className="linkBtn"
      onClick={async () => {
        logAmpEvent(events.USER_CLICKED_CANCEL_PAYMENT, { 'Payment Status': status, 'On Page': page });
        if (cancelAllowed) await cancelPayment(jwt, seq, exp);
        window.location.replace(returnUrl);
      }}
    >
      {t(cancelAllowed ? 'Landing_AbortPayment' : 'Btn_ReturnToStore', { partner: getPartnerName() })}
    </button>
  );
}

export default translate(CancelPaymentBtn);
