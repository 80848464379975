import React from 'react';

export default function ReplaceTextWithElement({ text, replaceKey, replaceWith }) {
  if (!text.includes(replaceKey)) return text;

  return text.split(replaceKey).map((e, i) => (i === 0 ? e : (
    <React.Fragment key={e}>
      {replaceWith}
      {e}
    </React.Fragment>
  )));
}

export function ItalicizeInterac({ text }) {
  return <ReplaceTextWithElement text={text} replaceKey="Interac" replaceWith={<i>Interac</i>} />;
}
