import { useEffect } from 'react';
import { getLanguage, t, translate } from 'react-switch-lang';

import { events, getPartnerName, logAmpEvent } from '../utils/Amplitude';
import PageTitle from '../utils/PageTitle';
import './ErrorPage.scss';
import { SkipToBtn } from './SkipToContent';
import Header from './Header';
import { ItalicizeInterac } from './ReplaceTextWithElement';
import ErrorPage, { ErrorContent } from './ErrorPage';
import { formatCurrency } from '../utils/Helpers';
import directPayLogo from '../images/DirectPay.png';

function CustomErrorPage({
  titleKey = 'ErrorPage_Title',
  errorCode,
  returnUrl,
  ampProp = undefined,
  lang = undefined, // only represents initial lang query param, use getLanguage() for actual lang
}) {
  const errorMsgKey = `CustomErrorPage_${errorCode}`;
  const searchParam = new URLSearchParams(window.location.search);
  const limit = Number(searchParam.get('limit')) || undefined;
  const errorMsgObj = {};
  if (t(errorMsgKey) !== errorMsgKey) { // error code exists in lang file
    errorMsgObj.en = t(errorMsgKey, { partner: getPartnerName('en'), amount: limit ? formatCurrency(limit, 'en', true) : '' }, 'en');
    errorMsgObj.fr = t(errorMsgKey, { partner: getPartnerName('fr'), amount: limit ? formatCurrency(limit, 'fr', true) : '' }, 'fr');
  } else {
    errorMsgObj.en = searchParam.get('msg_en') || t('ErrorPage_Body', { partner: getPartnerName('en') });
    errorMsgObj.fr = searchParam.get('msg_fr') || t('ErrorPage_Body', { partner: getPartnerName('fr') });
  }

  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_ERROR_PAGE, {
      'Displayed Message': errorMsgObj.en,
      'Error Code': errorCode,
      ...ampProp,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (lang === 'fr' || lang === 'en') {
    return (
      <ErrorPage
        ampEvent={null}
        errorMsg={errorMsgObj[getLanguage() === 'fr' ? 'fr' : 'en']}
        titleKey={titleKey}
        returnUrl={returnUrl}
      />
    );
  }

  return (
    <>
      <div id="SkipTo">
        <SkipToBtn skipTo="#content" text={`${t('LinkDescription_SkipToMain', null, 'en')} / ${t('LinkDescription_SkipToMain', null, 'fr')}`} />
        <SkipToBtn skipTo="footer" text={`${t('LinkDescription_SkipToFooter', null, 'en')} / ${t('LinkDescription_SkipToFooter', null, 'fr')}`} />
      </div>
      <PageTitle title={`${t('PageTitle_Error', null, 'en')} / ${t('PageTitle_Error', null, 'fr')}`} />
      <div id="container">
        <Header />
        <main>
          <div id="content" className="custom">
            <ErrorContent
              titleKey={titleKey}
              pageTitleKey={null}
              errorMsg={errorMsgObj.en}
              returnUrl={returnUrl}
              ampEvent={null}
              lang="en"
            />
            <hr />
            <ErrorContent
              titleKey={titleKey}
              pageTitleKey={null}
              errorMsg={errorMsgObj.fr}
              returnUrl={returnUrl}
              ampEvent={null}
              lang="fr"
            />
          </div>
        </main>
        <footer className="custom">
          <div>
            <img src={directPayLogo} alt={t('ImageDescription_DirectPayLogo')} />
            <div>
              <div id="disclaimer" lang="en">
                <p><ItalicizeInterac text={t('Footer_Disclaimer', null, 'en')} /></p>
                <p>{t('Footer_Disclaimer2', null, 'en')}</p>
              </div>
              <div id="disclaimer" lang="fr">
                <p><ItalicizeInterac text={t('Footer_Disclaimer', null, 'fr')} /></p>
                <p>{t('Footer_Disclaimer2', null, 'fr')}</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default translate(CustomErrorPage);
