import { t, translate } from 'react-switch-lang';
import { useEffect } from 'react';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

import IconTextContainer from '../IconTextContainer';
import { events, getPartnerName, logAmpEvent } from '../../utils/Amplitude';
import { setPageTitle } from '../../utils/PageTitle';
import { AMP_PROP_EXPERIENCE_TYPE } from '../../utils/Constants';
import { useWindowFocusStateListener } from '../../utils/Helpers';

const TIME_UNTIL_REDIRECT = 5000;

function PaymentDeposited({ returnUrl, device }) {
  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_PAYMENT_RECEIVED_PAGE, { 'Experience Type': AMP_PROP_EXPERIENCE_TYPE[device] });
    setPageTitle(t('PageTitle_PaymentDeposited'));
  }, [device]);
  useEffect(() => {
    if (!returnUrl) return null;
    const id = setTimeout(() => {
      logAmpEvent(events.REDIRECTING_BACK_TO_STORE);
      window.location.replace(returnUrl);
    }, TIME_UNTIL_REDIRECT);
    if (process.env.REACT_APP_ENV === 'test' && window.debug) clearTimeout(id);
    return () => clearTimeout(id);
  }, [returnUrl]);
  useWindowFocusStateListener();

  return (
    <IconTextContainer icon={faCheck} title="PaymentResolved_Title" className="green">
      <p>{t('PaymentResolved_Info', { partner: getPartnerName() })}</p>
    </IconTextContainer>
  );
}

export default translate(PaymentDeposited);
