import { useState, useEffect, useMemo } from 'react';
import { setUser as setSentryUser } from '@sentry/react';

import Layout from './components/Layout';
import Main from './components/Main/Main';
import LoadingPage from './components/LoadingPage';
import { Provider } from './PartnerPayContext';
import { queryPayment } from './utils/ApiClient';
import { PAYMENT_STATUS } from './utils/Constants';
import { setAmpUser } from './utils/Amplitude';
import { isProduction } from './utils/HostingEnv';
import './App.scss';

export default function App({ jwt, seq, exp, testMode }) {
  const [loading, setLoading] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState({});
  const context = useMemo(() => ({ jwt, seq, exp, testMode }), [jwt, seq, exp, testMode]);

  useEffect(() => {
    queryPayment(jwt, seq, exp).then((resp) => {
      const userId = resp.Email || resp.PhoneNumber;
      if (userId) {
        setAmpUser(userId);
        if (isProduction) setSentryUser({ id: userId });
      }

      setPaymentInfo({
        ...resp,
        PaymentURL: resp.Status === PAYMENT_STATUS.INITIATED ? resp.PaymentURL : undefined,
      });
      if (resp.Status !== PAYMENT_STATUS.EXPIRED) {
        setTimeout(
          () => setPaymentInfo({ ...resp, Status: PAYMENT_STATUS.EXPIRED }),
          exp - Date.now()
        );
      }
    }).finally(() => setLoading(false));
  }, [jwt, seq, exp]);

  const setStatus = (status) => setPaymentInfo((prevState) => ({ ...prevState, Status: status }));
  if (process.env.REACT_APP_ENV === 'test' && window.debug) window.setStatus = (s) => { window.paymentStatus = s; setStatus(s); };

  if (loading) return <LoadingPage />;

  return (
    <Provider value={context}>
      <Layout amount={paymentInfo.Amount} status={paymentInfo.Status}>
        <Main
          amount={paymentInfo.Amount}
          status={paymentInfo.Status}
          setStatus={setStatus}
          returnUrl={paymentInfo.ReturnURL}
          paymentUrl={paymentInfo.PaymentURL}
        />
      </Layout>
    </Provider>
  );
}
