import { useEffect } from 'react';

export default function PageTitle({ title = undefined }) {
  useEffect(() => {
    if (title) document.title = `${title} | DirectPay`;
  }, [title]);
  return (
    <div
      id="PageTitle"
      role="alert"
      aria-live="assertive"
      className="visually-hidden"
    >
      {title ? `${title} | DirectPay` : 'DirectPay'}
    </div>
  );
}

export const setPageTitle = (title) => {
  document.title = `${title} | DirectPay`;
  const e = document.getElementById('PageTitle');
  if (e) e.textContent = document.title;
};
