import { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import SkipToContent from './SkipToContent';
import PageTitle from '../utils/PageTitle';
import { PAYMENT_STATUS } from '../utils/Constants';

let Debug;
if (process.env.REACT_APP_ENV === 'test') {
  const activeStyle = { backgroundColor: '#555', color: 'white', borderStyle: 'solid', borderColor: '#555' };
  // eslint-disable-next-line react/function-component-definition
  const DebugBtn = ({ setTo, current, ...props }) => {
    let text = setTo;
    if (setTo === 404) text = 'NOT FOUND';
    if (setTo === 500) text = 'ERROR';
    return (
      <button
        onClick={() => window.setStatus(setTo)}
        style={setTo === current ? activeStyle : undefined}
        {...props}
        type="button"
      >
        {text}
      </button>
    );
  };
  Debug = function ({ status }) { // eslint-disable-line func-names
    const [show, showDebugPanel] = useState(true);

    return (
      <div className="parent">
        <style>
          {`
          .parent {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(${show ? 13 : 1}, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            width: 150px;
            position: fixed;
            right: 0;
            z-index: 99;
          }
          
          .parent button:nth-of-type(1) { grid-area: 1 / 1 / 2 / 3; }
          .parent button:nth-of-type(2) { grid-area: 2 / 1 / 3 / 3; }
          .parent button:nth-of-type(3) { grid-area: 3 / 1 / 4 / 3; }
          .parent button:nth-of-type(4) { grid-area: 4 / 1 / 5 / 2; }
          .parent button:nth-of-type(5) { grid-area: 4 / 2 / 5 / 3; }
          .parent button:nth-of-type(6) { grid-area: 5 / 1 / 6 / 3; }

          .parent button:nth-of-type(7) { grid-area: 7 / 1 / 8 / 3; }
          .parent button:nth-of-type(8) { grid-area: 8 / 1 / 9 / 3; }
          .parent button:nth-of-type(9) { grid-area: 9 / 1 / 10 / 3; }
          .parent button:nth-of-type(10) { grid-area: 10 / 1 / 11 / 3; }
          .parent button:nth-of-type(11) { grid-area: 11 / 1 / 12 / 3; }

          .parent button:nth-of-type(12) { grid-area: 13 / 1 / 14 / 3; }
          .parent button:nth-of-type(13) { grid-area: 14 / 1 / 15 / 3; }
          `}
        </style>
        <button type="button" onClick={() => showDebugPanel(!show)}>{show ? 'HIDE' : 'DEBUG PANEL'}</button>
        {show && (
          <>
            <DebugBtn current={status} setTo={PAYMENT_STATUS.AVAILABLE} />
            <DebugBtn current={status} setTo={PAYMENT_STATUS.INITIATED} />
            <button
              type="button"
              onClick={() => {
                window.delayed = false;
                window.setStatus(PAYMENT_STATUS.PENDING);
                window.setDelayed?.(false);
              }}
              style={status === PAYMENT_STATUS.PENDING && !window.delayed ? activeStyle : undefined}
            >
              PENDING
            </button>
            <button
              type="button"
              disabled={status !== PAYMENT_STATUS.PENDING}
              onClick={() => {
                window.delayed = true;
                window.setStatus(PAYMENT_STATUS.PENDING);
                window.setDelayed?.(true);
              }}
              style={status === PAYMENT_STATUS.PENDING && window.delayed ? activeStyle : undefined}
            >
              DELAYED
            </button>
            <DebugBtn current={status} setTo={PAYMENT_STATUS.DEPOSITED} />
            <DebugBtn current={status} setTo={PAYMENT_STATUS.EXPIRED} />
            <DebugBtn current={status} setTo={PAYMENT_STATUS.CANCELLED} />
            <DebugBtn current={status} setTo={PAYMENT_STATUS.REJECTED} />
            <DebugBtn current={status} setTo={404} />
            <DebugBtn current={status} setTo={500} />
            <button
              type="button"
              onClick={() => {
                const windowHref = new URL(window.location.href);
                windowHref.searchParams.append('loading', 'true');
                window.open(windowHref.href);
              }}
            >
              LOADING PAGE ↗
            </button>
            <button
              type="button"
              onClick={() => {
                const windowHref = new URL(window.location.href);
                windowHref.searchParams.delete('error_code');
                windowHref.searchParams.delete('return');
                windowHref.searchParams.append('return', windowHref);
                windowHref.searchParams.append('error_code', '1001');
                window.location.replace(windowHref.href);
              }}
            >
              CUSTOM ERROR ↻
            </button>
          </>
        )}
      </div>
    );
  };
}

export default function Layout({ amount, children, pageTitle, status }) {
  return (
    <>
      {process.env.REACT_APP_ENV === 'test' && window.debug && <Debug status={status} />}
      <SkipToContent status={status} />
      <PageTitle title={pageTitle} />
      <div id="container">
        <Header amount={amount} />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
}
