import { t, translate } from 'react-switch-lang';
import { PAYMENT_STATUS } from '../../utils/Constants';
import './ProgressBar.scss';

function ProgressBar({ status }) {
  return (
    <div id="ProgressBar">
      <ol aria-label={t('ListDescription_ProgressBar')}>
        <Step active={status === PAYMENT_STATUS.INITIATED}>{t('ProgressBar_Waiting')}</Step>
        <Step active={status === PAYMENT_STATUS.VERIFIED || status === PAYMENT_STATUS.PENDING}>
          {t('ProgressBar_Processing')}
        </Step>
        <Step active={status === PAYMENT_STATUS.DEPOSITED}>{t('ProgressBar_Successful')}</Step>
      </ol>
    </div>
  );
}

function Step({ active, children }) {
  return (
    <li className={active ? 'active' : undefined} aria-current={active ? 'step' : undefined}>
      <span>{t(children)}</span>
    </li>
  );
}

export default translate(ProgressBar);
