import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { events, logAmpEvent } from './Amplitude';

/**
 * @param {boolean} omitDecimal use with caution as 1.50 would be formatted as 1.5 instead of 1.50
 */
export function formatCurrency(amount, lang, omitDecimal = false) {
  const formatter = new Intl.NumberFormat(`${lang}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: omitDecimal ? 0 : 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
}

export function useWindowFocusStateListener(onFocusCallback, onBlurCallback) {
  const isWindowFocusedRef = useRef();
  const [onFocus, setOnFocus] = useState(onFocusCallback);
  const [onBlur, setOnBlur] = useState(onBlurCallback);
  useEffect(() => {
    isWindowFocusedRef.current = document.hasFocus();
    const handleWindowFocus = () => {
      logAmpEvent(events.USER_RETURNED_TO_WINDOW, { Device: isMobile ? 'Mobile' : 'Desktop' });
      isWindowFocusedRef.current = true;
      onFocus?.();
    };
    const handleWindowBlur = () => {
      logAmpEvent(events.USER_BLURRED_FROM_WINDOW, { Device: isMobile ? 'Mobile' : 'Desktop' });
      isWindowFocusedRef.current = false;
      onBlur?.();
    };
    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, [onFocus, onBlur]);

  return [isWindowFocusedRef, setOnFocus, setOnBlur];
}
