import React from 'react';
import { t, getLanguage, translate } from 'react-switch-lang';

import { formatCurrency } from '../utils/Helpers';
import { getPartnerName } from '../utils/Amplitude';
import './Header.scss';
import interacLogo from '../images/Interac.svg';

function Header({ amount = null }) {
  return (
    <header>
      <img src={interacLogo} alt={t('ImageDescription_InteracLogo')} />
      {amount && (
        <div className="amount">
          <span className="amount">{formatCurrency(amount, getLanguage())}</span>
          <span>{t('Header_Payee', { partner: getPartnerName() })}</span>
        </div>
      )}
    </header>
  );
}

export default translate(Header);
