import { t, translate } from 'react-switch-lang';
import { events } from '../../utils/Amplitude';
import Collapsible from '../Collapsible';
import { ItalicizeInterac } from '../ReplaceTextWithElement';
import './InfoBlock.scss';
import Link from '../Link';

function PaymentInstructionsBody() {
  return (
    <>
      <p><ItalicizeInterac text={t('HowItWorks_Info1')} /></p>
      <p>{t('HowItWorks_Info2')}</p>
      <ol>
        <li>{t('HowItWorks_Point1')}</li>
        <li>{t('HowItWorks_Point2')}</li>
        <li>{t('HowItWorks_Point3')}</li>
      </ol>
    </>
  );
}

function InfoBlock({ collapsed }) {
  if (collapsed) {
    return (
      <Collapsible
        id="InfoBlock"
        isCollapsed
        collapsedUI={<h2>{t('HowItWorks_Title')}</h2>}
        contentId="PaymentInstructionsBody"
      >
        <PaymentInstructionsBody />
      </Collapsible>
    );
  }

  return (
    <>
      <div id="InfoBlock">
        <h2>{t('HowItWorks_Title')}</h2>
        <PaymentInstructionsBody />
      </div>
      <div id="Limits">
        <h3>{t('Limits_Title')}</h3>
        <p>
          {t('Limits_Body')}
          {' '}
          <Link
            href={t('Limits_HelpLink')}
            ampEvent={events.USER_CLICKED_LIMITS_HELP_LINK}
            aria-label={t('LinkDescription_Limits')}
            text={t('Limits_LinkLabel')}
          />
        </p>
      </div>
    </>
  );
}

export default translate(InfoBlock);
