import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { logAmpEvent } from '../utils/Amplitude';

export default function Button({
  text,
  onClick,
  id = undefined,
  className = '',
  ampEvent,
  ampEventProps = undefined,
  ampIsMobile = undefined,
  ariaLabel = text,
  disabled = false,
  loading: loadingProp,
}) {
  const [loading, setLoading] = useState(loadingProp);
  useEffect(() => setLoading(loadingProp), [loadingProp]);
  return (
    <button
      id={id}
      aria-label={ariaLabel}
      title={ariaLabel}
      disabled={disabled || loading}
      className={`btn ${className}`}
      type="button"
      onClick={() => {
        if (loading) return;
        logAmpEvent(ampEvent, ampEventProps, ampIsMobile);
        if (onClick) {
          setLoading(true);
          Promise.resolve(onClick()).then(() => setLoading(false));
        }
      }}
    >
      {loading ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : text }
    </button>
  );
}
